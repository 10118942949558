import axios from 'axios'
const { get } = axios.create()

export default class SessionHeartBeat {
  PROTECTED_PATHS = [
    '/users/sign_in',
    '/admin',
    '/users/password/new',
    '/users/invitation/accept',
  ]
  // tick every 10 minutes
  HEARTBEAT_INTERVAL = 1000 * 60 * 10
  // sleep can be (false positive) - this happens when they the browser is minimized or tab is not focused for a longer time
  // therefore we limit the amount of requests to reduce the server load
  REQUEST_INTERVAL_LIMIT = this.HEARTBEAT_INTERVAL / 2

  lastRequestAt = Date.now()

  start() {
    this.detectSleep()

    setInterval(() => {
      this.request()
    }, this.HEARTBEAT_INTERVAL)
  }

  // detect whether PC was in sleep mode
  // if it was, perform the request
  detectSleep() {
    let lastTimeTick = Date.now()

    setInterval(() => {
      const currentTime = Date.now()
      // if the difference is more than 2 seconds, it means the user was in sleep mode
      if (currentTime > lastTimeTick + 2000 * 2) {
        this.request()
      }
      lastTimeTick = currentTime
    }, 2000)
  }

  async request() {
    // never check session timeout for the login page
    if (this.PROTECTED_PATHS.includes(document.location.pathname)) {
      return
    }

    if (Date.now() <= this.lastRequestAt + this.REQUEST_INTERVAL_LIMIT) {
      return
    }

    this.lastRequestAt = Date.now()

    let sessionTimeout = false

    try {
      const {
        data: { ttl },
      } = await get(
        '/users/check_session_timeout.json',
        {},
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      )

      if (ttl <= 0) {
        sessionTimeout = true
      }
    } catch (error) {
      sessionTimeout = true
    }

    if (sessionTimeout) {
      window.location.href = '/'
    }
  }
}
